import React from "react";
import ReactDOM from "react-dom";
import AssetLinks from "../core/AssetLinks"

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    Link,
    Outlet,
    useParams,
    NavLink,
    useNavigate,
    useLocation
} from 'react-router-dom'
import LandingPage from "../screens/landingPage/LandingPage";
import AttributionsPage from "../screens/attribution/Attributions";
import PrivacyPolicyPage from "../screens/privacy/PrivacyPage";
import AttributionsAndroidPage from "../screens/attribution/AttributionsAndroid";


const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/attributions" element={<AttributionsPage />} />
                <Route path="/attributions-android" element={<AttributionsAndroidPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/404.html" element={<LandingPage />} />
                {/* <Route path="/.well-known/assetlinks.json" element={<AssetLinks />} /> */}
            </Routes>
        </Router>)
}

export default AppRoutes
