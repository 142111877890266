import "../landingPage/LandingPage.css";
import "./Attributions.css";
import React from "react";

const AttributionsPage: React.FC = () => {
	return (
		<div className="landing-page1">
			<header>
				<div className="header-content">
					<div className="logo">
						<a
							href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="../../resources/images/focus-list-logo.png"
								alt="FocusList Logo"
							/>
						</a>
						<h1 className="topBar-brandName">FocusList</h1>
					</div>
					<a
						href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release"
						target="_blank"
						rel="noopener noreferrer"
						className="playstore-button"
					>
						<img
							src="../../resources/images/google-play-badge.png"
							alt="Play Store"
						/>
					</a>
				</div>
			</header>

			<section className="parent-container1">
				<div className="title">
					<h2>Attributions</h2>
				</div>

				<div className="row">
					<img
						className="img"
						src="../../resources/images/reminder.png"
						alt="Reminder Icon"
					/>
					<a
						href="https://www.flaticon.com/free-icons/reminder"
						title="reminder icons"
						rel="noopener noreferrer"
						target="_blank"
					>
						Reminder icons created by Darius Dan - Flaticon
					</a>
				</div>

				<div className="row">
					<img
						className="img"
						src="../../resources/images/project.png"
						alt="Projects Icon"
					/>
					<a
						href="https://www.flaticon.com/free-icons/rocket"
						title="rocket icons"
						rel="noopener noreferrer"
						target="_blank"
					>
						Rocket icons created by Freepik - Flaticon
					</a>
				</div>

				<div className="row">
					<img
						className="img"
						src="../../resources/images/stopwatch.png"
						alt="Timer Icon"
					/>
					<a
						href="https://www.flaticon.com/free-icons/timer"
						title="timer icons"
						rel="noopener noreferrer"
						target="_blank"
					>
						Timer icons created by Freepik - Flaticon
					</a>
				</div>

				<div className="row">
					<img
						className="img"
						src="../../resources/images/filter.png"
						alt="Filter Icon"
					/>
					<a
						href="https://www.flaticon.com/free-icons/filter"
						title="filter icons"
						rel="noopener noreferrer"
						target="_blank"
					>
						Filter icons created by Freepik - Flaticon
					</a>
				</div>

				<div className="row">
					<img
						className="img"
						src="../../resources/images/recurring.png"
						alt="Recurring Icon"
					/>
					<a
						href="https://www.flaticon.com/free-icons/recurrent"
						title="recurrent icons"
						rel="noopener noreferrer"
						target="_blank"
					>
						Recurrent icons created by Freepik - Flaticon
					</a>
				</div>

				<div className="row">
					<img
						className="img"
						src="../../resources/images/tag.png"
						alt="Tag Icon"
					/>
					<a
						href="https://www.flaticon.com/free-icons/price-tag"
						title="price tag icons"
						rel="noopener noreferrer"
						target="_blank"
					>
						Price tag icons created by Freepik - Flaticon
					</a>
				</div>
			</section>

			<footer className="footer1">
				<p>FocusList &copy; {new Date().getFullYear()}. All rights reserved.</p>
				<a href="attributions">
					<p className="attributions">Attributions</p>
				</a>
			</footer>
		</div>
	);
};

export default AttributionsPage;
