import React from 'react';
import './LandingPage.css';

const LandingPage: React.FC = () => {

  return (
    <div className="landing-page">
      <header>
     
        <div className="header-content">
          <div className="logo">
          <a href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release" target="_blank" rel="noopener noreferrer" >
              <img src="../../resources/images/focus-list-logo.png" alt="FocusList Logo" />
              </a>
            <h1 className='topBar-brandName'>FocusList</h1>
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release" target="_blank" rel="noopener noreferrer" className="playstore-button">
            <img src="../../resources/images/google-play-badge.png" alt="Play Store" />
          </a>
          </div>
          
      </header>

      <div className="parent-container">
      {/* <section className="hero">
        <div className="hero-content">
          <div className="screenshot1">
            <img src="../../resources/images/hero-screenshot3.png" alt="App Screenshot" />
          </div>
        </div>
        </section> */}
        <section className="hero">
        <div className="hero-content">
          <div className="screenshot">
            <img src="../../resources/images/hero-screenshot.png" alt="App Screenshot" />
          </div>
          </div>
          </section>
        <section className='brand_container'>
          <section>
          <div >
            <h1>Focus List App</h1>
            </div>
          <div >
            <h2>Focus List App is the ultimate productivity tool that helps you manage tasks, projects, and stay focused. It offers reminders, project tracking, customizable tags, a Pomodoro Timer, and powerful filters for efficient task management. With its user-friendly interface, it enhances productivity and organization for all users.</h2>
            </div>
         
          <a href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release" target="_blank" rel="noopener noreferrer" className="playstore-button1">
            <img src="../../resources/images/google-play-badge.png" alt="Play Store" />
          </a>
          </section>
          </section>
      </div>
      

      <section className="features">
        <div className="feature">
          {/* <a href="https://www.flaticon.com/free-icons/reminder" title="reminder icons">Reminder icons created by Darius Dan - Flaticon</a> */}
          <img src="../../resources/images/reminder.png" alt="Reminder Icon" />
          <h2>Task Reminders</h2>
          <p>Never forget important tasks with custom tags and recurring reminders.</p>
        </div>
        <div className="feature">
        {/* <a href="https://www.flaticon.com/free-icons/rocket" title="rocket icons">Rocket icons created by Freepik - Flaticon</a> */}
          <img src="../../resources/images/project.png" alt="Projects Icon" />
          <h2>Projects</h2>
          <p>Manage larger goals with task deadlines and track your progress.</p>
        </div>
        <div className="feature">
        {/* <a href="https://www.flaticon.com/free-icons/timer" title="timer icons">Timer icons created by Freepik - Flaticon</a> */}
          <img src="../../resources/images/stopwatch.png" alt="Timer Icon" />
          <h2>Pomodoro Timer</h2>
          <p>Improve focus and time management with the built-in Pomodoro Timer.</p>
        </div>
        <div className="feature">
        {/* <a href="https://www.flaticon.com/free-icons/filter" title="filter icons">Filter icons created by Freepik - Flaticon</a> */}
          <img src="../../resources/images/filter.png" alt="Filter Icon" />
          <h2>Filters</h2>
          <p>Sort and view tasks based on custom criteria using filters.</p>
        </div>

        <div className="feature">
        {/* <a href="https://www.flaticon.com/free-icons/recurrent" title="recurrent icons">Recurrent icons created by Freepik - Flaticon</a> */}
          <img src="../../resources/images/recurring.png" alt="Recurring Icon" />
          <h2>Recurring Tasks</h2>
          <p>Set customized recurring tasks, that repeats as you wish.</p>
        </div>

        <div className="feature">
        {/* <a href="https://www.flaticon.com/free-icons/price-tag" title="price tag icons">Price tag icons created by Freepik - Flaticon</a> */}
          <img src="../../resources/images/tag.png" alt="Tag Icon" />
          <h2>Categories & Tags</h2>
          <p>Associate tasks with custom tags, and filter tasks on the basis of tags</p>
        </div>
      </section>

      <section>

      </section>

      <footer>
        <p>FocusList &copy; {new Date().getFullYear()}. All rights reserved.</p>
        <a href='/attributions'>
          <p className='attributions'>Attributions</p>
          </a>
      </footer>
    </div>
  );
};

export default LandingPage;

