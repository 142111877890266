import "../landingPage/LandingPage.css";
import "./Attributions.css";
import React from "react";
import Lottie from "lottie-react";
import onboardingAnim from "../../resources/raw/onboarding_screen_task.json";

const AttributionsAndroidPage: React.FC = () => {
	return (
		<div className="landing-page1">
			<header>
				<div className="header-content">
					<div className="logo">
						<a
							href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="../../resources/images/focus-list-logo.png"
								alt="FocusList Logo"
							/>
						</a>
						<h1 className="topBar-brandName">FocusList</h1>
					</div>
					<a
						href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release"
						target="_blank"
						rel="noopener noreferrer"
						className="playstore-button"
					>
						<img
							src="../../resources/images/google-play-badge.png"
							alt="Play Store"
						/>
					</a>
				</div>
			</header>

			<section className="parent-container1">
				<div className="title">
					<h2>Android App Attributions</h2>
				</div>

				<div className="row">
					{/* <Lottie animationData={onboardingAnim} loop={true} />; */}
					<a
						href="https://lottiefiles.com/shinan"
						title="task man"
						rel="noopener noreferrer"
						target="_blank"
					>
						TaskMan - Shinan
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/76195-completing-tasks"
						title="completing task"
						rel="noopener noreferrer"
						target="_blank"
					>
						Completing Tasks - Canberk
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/76195-completing-tasks"
						title="completing task"
						rel="noopener noreferrer"
						target="_blank"
					>
						Completing Tasks - Canberk
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/15150-repeat-reload-animation"
						title="Repeat/ Reload Animation"
						rel="noopener noreferrer"
						target="_blank"
					>
						Repeat/ Reload Animation - Vaidas
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/135745-bell-reminder-animation"
						title="Bell reminder Animation"
						rel="noopener noreferrer"
						target="_blank"
					>
						Bell reminder Animation - Shehzad Abbasi
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/77077-discount-tag-purple"
						title="Discount Tag Purple"
						rel="noopener noreferrer"
						target="_blank"
					>
						Discount Tag Purple - Stewart Barrett
					</a>
				</div>

				<div className="row">
					<a
						href="https://lottiefiles.com/animations/no-internet-96EoG9mzJw"
						title="No Internet!"
						rel="noopener noreferrer"
						target="_blank"
					>
						No Internet!
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/animations/no-internet-connection-Lb6p8vJf83"
						title="No Internet Connection"
						rel="noopener noreferrer"
						target="_blank"
					>
						No Internet Connection
					</a>
				</div>

				<div className="row">
					<a
						href="https://lottiefiles.com/animations/loading-sand-clock-YwwRRL2vx4"
						title="Loading Sand Clock"
						rel="noopener noreferrer"
						target="_blank"
					>
						Loading Sand Clock
					</a>
				</div>
				<div className="row">
					<a
						href="https://lottiefiles.com/animations/rocket-loader-oHZjsDsGUV"
						title="Rocket Loader"
						rel="noopener noreferrer"
						target="_blank"
					>
						Rocket Loader
					</a>
				</div>
			</section>

			<footer className="footer1">
				<p>FocusList &copy; {new Date().getFullYear()}. All rights reserved.</p>
				<a href="attributions">
					<p className="attributions">Attributions</p>
				</a>
			</footer>
		</div>
	);
};

export default AttributionsAndroidPage;
