import React from "react";
import "../privacy/PrivacyPage.css";

const PrivacyPolicyPage: React.FC = () => {
	return (
		<div>
			<header>
				<div className="header-content">
					<div className="logo">
						<a
							href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="../../resources/images/focus-list-logo.png"
								alt="FocusList Logo"
							/>
						</a>
						<h1 className="topBar-brandName">FocusList</h1>
					</div>
					<a
						href="https://play.google.com/store/apps/details?id=com.focuslist.focuslistmobile.android.release"
						target="_blank"
						rel="noopener noreferrer"
						className="playstore-button"
					>
						<img
							src="../../resources/images/google-play-badge.png"
							alt="Play Store"
						/>
					</a>
				</div>
			</header>
			<div className="privacy-content">
				<h1>Privacy Policy</h1>
				<p>Updated on : 29th May 2023</p>

				<p>
					Thank you for using our Android app FOCUS LIST. We value your privacy
					and are committed to protecting your personal information. This
					Privacy Policy outlines how we collect, use, and safeguard your data
					when you use our App.
				</p>

				<h2>Your data</h2>
				<p>
					All personal data is stored locally on your device and is protected
					from access by other applications.
				</p>

				<h2>Information we collect</h2>
				<p>
					We do collect anonymous statistical usage data in the form of
					analytics. This is stored on third-party servers Firebase Analytics
					and is used to improve the application, service and bug fix along with
					users feedback.
				</p>

				<h2>Security</h2>
				<p>
					All data and information is stored locally on your device and is
					protected from access by other applications.
				</p>

				{/* 
				<h2>6. Third-Party Links</h2>
				<p>
					The App may contain links to third-party websites or services. We are
					not responsible for the privacy practices or content of such third
					parties. We encourage you to review the privacy policies of those
					third parties before providing any personal information.
				</p> */}

				<h2>Changes to the Privacy Policy</h2>
				<p>
					We reserve the right to update or modify this Privacy Policy at any
					time. Any changes will be effective upon posting the revised Privacy
					Policy within the App. We encourage you to review this Privacy Policy
					periodically for any updates.
				</p>

				<h2>How to contact us</h2>
				<p>
					We try to be as transparent as possible. However, If you have any
					questions about the Privacy Policy or the Service, please email us at <b>contact@focuslist.in</b>.
					
				</p>

				<p>
					By using our App, you signify your acceptance of this Privacy Policy.
					If you do not agree with the terms outlined in this policy, please do
					not use the App.
				</p>

        <p>Thank you for choosing our App and trusting us with your privacy.</p>
        <p>I hope this app helps you unlock your ultimate productivity, Saurabh</p>
			</div>

			<footer className="footer1">
				<p>FocusList &copy; {new Date().getFullYear()}. All rights reserved.</p>
				<a href="attributions">
					<p className="attributions">Attributions</p>
				</a>
			</footer>
		</div>
	);
};

export default PrivacyPolicyPage;
